/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a",
    p: "p",
    section: "section",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {Emoji} = _components;
  if (!Emoji) _missingMdxReference("Emoji", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#hard-skills"
  }, "Hard Skills")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#soft-skills"
  }, "Soft Skills")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#humor"
  }, "Humor")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#wrap-up"
  }, "Wrap-up")), "\n"), "\n", React.createElement(_components.p, null, "As a self-confessed YouTube addict, I think watching YouTube videos is an effective tool for learning software development. YouTube videos are a useful tool in the learning toolkit because they are particularly good for captivating the audience, especially through humor, and they are convenient because they are available on demand. Here are my picks for the best software development YouTube channels, in no particular order:"), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "hard-skills",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hard-skills",
    "aria-label": "hard skills permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hard Skills"), React.createElement(_components.p, null, "These YouTubers have great technical content; I mostly gravitate to web development specific channels."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/c/Fireship",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Fireship.io"), " - A well-known developer's channel with incredible content on software development technology. The series '100 Seconds of Code' is fantastic and comes highly recommended."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/kepowob",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kevin Powell"), " - This is an awesome channel for staying up to date with HTML and CSS. Lots of writing on modern CSS and accessability."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/c/JackHerrington",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Jack Herrington"), " - A great channel for staying up-to-date with JavaScript. Everything on JavaScript, front-end frameworks, and architecture."), "\n")), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "soft-skills",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#soft-skills",
    "aria-label": "soft skills permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Soft Skills"), React.createElement(_components.p, null, "These YouTube channels feature excellent interviews and career guidance content."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/c/EngineeringwithUtsav",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Engineering with Utsav"), " - An experienced developer's channel with a great resume and interview video series."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/c/DorianDevelops",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Dorian Develops"), " - A channel run by a self-taught developer with excellent motivational and career advice."), "\n")), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "humor",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#humor",
    "aria-label": "humor permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Humor"), React.createElement(_components.p, null, "YouTube channels which poke fun at the software industry and developer culture ", React.createElement(Emoji, {
    symbol: "😆"
  }), "."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/c/KRAZAM",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Krazam"), " - Hilarious skits. A must-have subscription."), "\n")), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "wrap-up",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wrap-up",
    "aria-label": "wrap up permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wrap-up"), React.createElement(_components.p, null, "YouTube videos are fantastic for passing the time, and you may learn a lot from them. Please support outstanding content creators by liking their videos and subscribing to their channels, as a friendly reminder.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
